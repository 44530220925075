import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

export const preConfigApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}growth/wealth/preconfig`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const userConsentStatusApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}growth/wealth/user/profile`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const registerUserToGripApiCall = async (data) => {
  const { payload, userDetails } = data;
  let body = {
    gatewayId: "grip",
    bondType: "listed",
    consent: "Y"
  };
  if (payload.first_name !== "" && payload.last_name !== "" && payload.email !== "") {
    body = {
      ...body,
      user: {
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
        phone: userDetails?.phone
      }
    };
  }
  try {
    const res = await axios.post(`${BASE_URL}growth/wealth/user/register`, body, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
export const getAllGripBondsApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}growth/wealth/asset/list/listed/grip`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getBondDetailsApiCall = async ({ bondId }) => {
  try {
    const res = await axios.get(`${BASE_URL}growth/wealth/asset/listed/grip/${bondId}`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getRedirectionLinkApiCall = async ({ bondId }) => {
  try {
    const res = await axios.get(`${BASE_URL}growth/wealth/user/asset/redirect/listed/grip/${bondId}`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const getOrdersApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}growth/wealth/user/portfolio/listed/grip`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const redeemNowApiCall = async ({ payload }) => {
  try {
    const res = await axios.post(
      `${BASE_URL}rewards/v1/trackReferral`,
      {
        referralCode: payload,
        product: "wealth",
        productSubType: "default"
      },
      {
        headers: {
          auth_token: `${localStorage.getItem("auth_token")}`,
          device_id: localStorage.getItem("device_id")
        }
      }
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const yubiRedirectApiCall = async () => {
  const res = await axios.get(`${BASE_URL}growth/wealth/user/asset/redirect/listed/yubi/`, {
    headers: {
      auth_token: `${localStorage.getItem("auth_token")}`,
      device_id: `${localStorage.getItem("device_id")}`
    }
  });
  return res;
};

export const getWealthWaitlistApiCall = async () => {
  try {
    const res = await axios.get(`${BASE_URL}growth/waitlist/WEALTH`, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};

export const addWealthWaitlistApiCall = async ({ payload }) => {
  try {
    const res = await axios.post(`${BASE_URL}growth/waitlist/wealth/add`, payload, {
      headers: {
        auth_token: `${localStorage.getItem("auth_token")}`,
        device_id: localStorage.getItem("device_id")
      }
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
